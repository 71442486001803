
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Flickity from 'Flickity.vue';
import TrackingService from '@/core/tracking.service';

@Component({
    name: 'image-carousel'
})
export default class ImageCarousel extends Vue {
    public $refs!: {
    flickity: Flickity;
  };

    @Prop({ type: Array, default: () => [] })
    public images: CarouselImageViewObject[];

    @Prop({ type: String, default: '' })
    public bgColor: any;

     @Prop({ type: Boolean, default: false })
    public enableZoom: boolean;

     @Prop({ type: String, default: '' })
     public imageTitle: string;

     @Prop({
         required: false,
         type: Array,
         default: () => []
     })
     public fullScreenSpecificImages: CarouselImageViewObject[];

     private zoomModalToggled: boolean = false;
     private selectedZoomIndex: number = 0;

     private sliderIsDragging: boolean = false;
     private sliderIsDraggingTimeout: number = 0;

     get flickityOptions() {
         return {
             prevNextButtons: this.images.length > 1,
             pageDots: false,
             cellAlign: 'left',
             contain: true,
             adaptiveHeight: true,
             wrapAround: true
         };
     }

     private openZoomModal(index: number) {
         if (this.sliderIsDragging) {
             return;
         }
         this.selectedZoomIndex = index;
         this.zoomModalToggled = true;
     }

     closeZoomModal(): void {
         this.zoomModalToggled = false;
     }

     flickityInited(flickity: any) {
         flickity.select(this.getDefaultImageIndex());
         flickity.focus();
         this.disableClickOnDrag();
     }

     onImageChanged(index: any) {
         TrackingService.event('Mini page', 'Next image', index);
     }

    @Watch('images')
     reInitSlider() {
         if (this.$refs.flickity) {
             this.$refs.flickity.reinit();
         }
     }

    private getDefaultImageIndex(): number {
        return this.images.findIndex((img) => img.isDefault) || 0;
    }

    private handlePresentedImageClicked(index: number): void {
        this.$emit('on-image-clicked', index);
    }

    private disableClickOnDrag() {
        this.$refs.flickity.on('dragStart', () => {
            if (this.sliderIsDraggingTimeout) {
                clearTimeout(this.sliderIsDraggingTimeout);
            }
            this.sliderIsDragging = true;
        });
        this.$refs.flickity.on('dragEnd', () => {
            this.sliderIsDraggingTimeout = setTimeout(() => {
                this.sliderIsDragging = false;
            }, 100);
        });
    }

    private get fullScreenImages(): ImageWithLinkViewObject[] {
        const imagesToConvert: Array<CarouselImageViewObject | ImageWithLinkViewObject> = [...this.images, ...this.fullScreenSpecificImages];
        const convertedImages: ImageWithLinkViewObject[] = [];

        imagesToConvert.forEach((image: any) => {
            convertedImages.push({
                imageUrl: image.url || image.imageUrl,
                link: null,
                title: this.imageTitle || image.title,
                altText: this.imageTitle || image.title
            });
        });

        return convertedImages;
    }
}
