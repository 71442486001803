import Http from '../../../core/http/http.service';
import {
    SalesDocumentMobileOverlayType,
    SalesDocumentTableConfig
} from '@/ui/views/salesdocumenttable/salesdocument.types';

export default class QuoteService {

    public async quotes(request: QuoteSearchWebRequest) : Promise<QuoteSearchViewModel> {
        const url = 'quotation/search';

        return Http.cancelableRequest('POST', url, 'quoteRequest', request)
            .then((response: QuoteSearchViewModel) => {
                return response;
            });
    }

    public tableConfig(allowSortingAndFiltering:boolean = true):SalesDocumentTableConfig{
        return {
            rowKey: 'quoteNo',
            columns:[
                {
                    headerLabel: 'Pages.PartnerPortal.Quotes.QuotePage.QuoteTableQuoteNo',
                    widthClass: 'sub-md:u-w-6/24 u-w-2/24 u-mr-2',
                    ...(allowSortingAndFiltering && {sortField: 'quoteNo'}),
                    valueField: 'quoteNo',
                    valueClass: 'table-bold'
                },
                {
                    headerLabel: '',
                    widthClass: 'sub-md:u-hidden u-w-1/24 u-pr-2',
                    component: 'icon-pdf',
                    linkField: 'quotePdfLink',
                    linkTarget: '_blank',
                    hideContentField: 'quotePdfLink',
                    hiddenContentReplacement: 'N/A'
                },
                {
                    headerLabel: 'Pages.PartnerPortal.Quotes.QuotePage.QuoteTableReference',
                    widthClass: 'u-flex-1',
                    ...(allowSortingAndFiltering && {sortField: 'reference'}),
                    valueField: 'reference',
                },
                {
                    headerLabel: 'Pages.PartnerPortal.Quotes.QuotePage.QuoteTableCreationDate',
                    widthClass: 'sub-md:u-hidden u-w-3/24',
                    ...(allowSortingAndFiltering && {sortField: 'creationDateSortable'}),
                    valueField: 'creationDate',
                },
                {
                    headerLabel: 'Pages.PartnerPortal.Quotes.QuotePage.QuoteTableValidTo',
                    widthClass: 'sub-md:u-hidden u-w-3/24',
                    ...(allowSortingAndFiltering && {sortField: 'validToDateSortable'}),
                    valueField: 'validToDate',
                },
                {
                    headerLabel: 'Pages.PartnerPortal.Quotes.QuotePage.QuoteTableNetValue',
                    widthClass: 'sub-md:u-hidden u-w-4/24',
                    ...(allowSortingAndFiltering && {sortField: 'netValueSortable'}),
                    valueField: 'netValue',
                },
                {
                    headerLabel: 'Pages.PartnerPortal.Quotes.QuotePage.QuoteTableSalesOrderNo',
                    widthClass: 'sub-md:u-hidden u-w-3/24 u-pr-2',
                    ...(allowSortingAndFiltering && {sortField: 'orderNo'}),
                    valueField: 'orderNo',
                    linkField: 'orderLink',
                }
            ],
            defaultSort: {
                sortBy: 'creationDateSortable',
                direction: 'desc'
            },

            filterFields: allowSortingAndFiltering ? [
                'orderNo',
                'reference',
                'creationDate',
                'netValue',
                'customerName',
                'lines.variantId',
                'lines.description',
                'lines.netValue',
                'lines.invoiceNumber',
                'lines.reference',
            ] : [],

            subTableField: 'lines',
            subTableConfig: {
                rowKey: 'position',
                mobileOverlayType: SalesDocumentMobileOverlayType.quote,
                columns: [
                    {
                        headerLabel: 'Pages.PartnerPortal.Quotes.QuotePage.QuoteTableLinePos',
                        widthClass: 'md:u-w-1/24',
                        valueField: 'position',
                    },
                    {
                        headerLabel: '',
                        widthClass: 'md:u-w-70p md:u-mr-5',
                        imageField: 'imageUrl',
                        imagesField: 'images',
                        imageSize: 70
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Quotes.QuotePage.QuoteTableLineMaterial',
                        widthClass: 'md:u-w-3/24 u-pr-2',
                        valueField: 'variantId',
                        linkField: 'pdpLink',
                        salesInfoField: 'variantId'
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Quotes.QuotePage.QuoteTableLineDescription',
                        widthClass: 'md:u-flex-1 u-pr-2',
                        valueField: 'description',
                        valueClass: 'u-capitalize',
                        linkField: 'pdpLink'
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Quotes.QuotePage.QuoteTableLineQuantity',
                        widthClass: 'md:u-w-1/24 u-pr-2',
                        valueField: 'quantity',
                        valueClass: 'u-inline-block u-bg-black u-px-2 u-rounded-full u-text-white u-text-small'
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Quotes.QuotePage.QuoteTableLineNetValue',
                        widthClass: 'md:u-w-3/24 u-pr-2',
                        valueField: 'netValue',
                    },
                    {
                        headerLabel: 'Pages.PartnerPortal.Quotes.QuotePage.QuoteTableLineSalesOrderNo',
                        widthClass: 'md:u-w-3/24 u-pr-2',
                        valueField: 'orderNumber',
                        linkField: 'orderUrl'
                    }
                ]
            }
        }

    }
}

export const quoteService = new QuoteService();

export type SortingDirection = 'asc' | 'desc';

export interface ISorting {
    sortBy: string;
    direction: SortingDirection;
}
