var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.showBodyPadding)?_c('div',[_c('div',{staticClass:"body-padding",class:[_setup.showBodyPaddingBorder ? 'sub-lg:u-border-b' : 'u-border-0',
                  { 'u-mb-4 lg:u-mb-5': !_setup.hideNavigationSpacer && !_setup.menuTheme.removeSpacingBeforeContent,
                    '-narrow': _setup.isSearchPage,
                    '-usp': !_setup.breakpoints.large && _setup.usp,
                    '-show-auth-top-bar': _setup.showAuthTopBar,
                    '-empty-top-bar': _setup.showEmptyTopBar,
                    'u-bg-grey-4': _setup.hideNavigationSpacer && _setup.isPrivateMode,
                    'u-bg-white': _setup.hideNavigationSpacer && !_setup.isPrivateMode}]}),(_setup.borderAlwaysOnTop)?_c('div',{staticClass:"u-transition-colors u-duration-[400ms] u-top-0 u-border-b u-w-full",class:[_setup.borderOnTop ? 'u-fixed u-z-100' : 'u-absolute u-z-1', {'u-border-black' : _setup.isMegaMenuOpen }],attrs:{"id":"fixed-navigation-spacer"}}):_vm._e(),(_setup.theme.textColor)?_c("style",{tag:"Component"},[_vm._v("\n        .body-padding { border-color: "+_vm._s(_setup.theme.textColor)+" }\n    ")]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }