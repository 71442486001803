import Vue from 'vue';
import { addBreakpoint } from './core/responsive/breakpoints/breakpoint.service';
import App from './ui/App.vue';

// spa
import StoreSync from './ui/spa/StoreSync.vue';

// shared
import AccordionItem from './ui/shared/accordion/AccordionItem.vue';
import AddToBasket from './ui/shared/AddToBasket.vue';
import AddToBasketQuantity from '@/ui/shared/AddToBasketQuantity.vue';
import AddToBasketWithQuantity from '@/ui/shared/AddToBasketWithQuantity.vue';
import AddToQuoteBasket from './ui/shared/AddToQuoteBasket.vue';
import AddToBasketMultiple from '@/ui/shared/AddToBasketMultiple.vue';
import BlockLandscape from './ui/shared/blocks/BlockLandscape.vue';
import BlockFeaturedContent from './ui/shared/blocks/BlockFeaturedContent.vue';
import BlockSingleFeaturedContent from './ui/shared/blocks/BlockSingleFeaturedContent.vue';
import BlockFeaturedLandscapeContent from './ui/shared/blocks/BlockFeaturedLandscapeContent.vue';
import BlockFullWidthImageWithText from './ui/shared/blocks/BlockFullWidthImageWithText.vue';
import BlockLedCalculator from './ui/shared/blocks/LedCalculator/BlockLedCalculator.vue';
import BlockLedConfigurator from './ui/shared/blocks/BlockLedConfigurator.vue';
import LastViewedProducts from './ui/shared/LastViewedProducts.vue';
import BlockAddToBasketCTA from './ui/shared/blocks/BlockAddToBasketCTA.vue';
import BlockImageWithInfoPoints from './ui/shared/blocks/BlockImageWithInfoPoints.vue';
import BlockPortrait from './ui/shared/blocks/BlockPortrait.vue';
import BlockTeaserTextWithOverlay from './ui/shared/blocks/BlockTeaserTextWithOverlay.vue';
import BlockTextAndImage from './ui/shared/blocks/BlockTextAndImage.vue';
import BlockTextAndImageSideBySide from './ui/shared/blocks/BlockTextAndImageSideBySide.vue';
import BlockTopModule from './ui/shared/blocks/BlockTopModule.vue';
import BlockVideo from './ui/shared/blocks/BlockVideo.vue';
import BlockGdprForm from './ui/shared/blocks/BlockGdprForm.vue';
import BlockTraderSignupForm from './ui/shared/blocks/TraderSignupForm/BlockTraderSignupForm.vue';
import BlockYourStoriesForm from './ui/shared/blocks/BlockYourStoriesForm.vue';
import BlockCookiePolicy from './ui/shared/blocks/BlockCookiePolicy.vue';
import BlockDismantlingInstructions from './ui/shared/blocks/BlockDismantlingInstructions.vue';
import Breakpoint from './core/responsive/breakpoints/Breakpoints.vue';
import Dropdown from './ui/shared/Dropdown.vue';
import GridFallback from './ui/shared/grid/GridFallback.vue';
import InputField from './ui/shared/InputField.vue';
import PasswordField from '@/ui/shared/PasswordField.vue';
import Flickity from './ui/shared/Flickity.vue';
import FilterSlider from './ui/shared/FilterSlider.vue';
import Headline from './ui/shared/Headline.vue';
import Checkbox from './ui/shared/Checkbox.vue';
import CheckoutButtonB2B from './ui/views/checkout/CheckoutButtonB2B.vue';
import ImagePresenter from './ui/shared/image/ImagePresenter.vue';
import SimpleImagePresenter from './ui/shared/image/SimpleImagePresenter.vue';
import Logo from './ui/shared/Logo.vue';
import NewsletterForm from './ui/shared/NewsletterForm.vue';
import NewNewsletterForm from './ui/shared/NewNewsletterForm.vue';
import NoImage from './ui/shared/image/NoImage.vue';
import RadioButton from './ui/shared/RadioButton.vue';
import ImageCarousel from '@/ui/shared/ImageCarousel.vue';
import FullscreenImageCarousel from '@/ui/shared/FullscreenImageCarousel.vue';
import Modal from './ui/shared/Modal.vue';
import SleekModal from './ui/shared/SleekModal.vue';
import Flyover from './ui/shared/Flyover.vue';
import ProductTile from './ui/shared/product-tile/ProductTile.vue';
import ContentTile from './ui/shared/ContentTile.vue';
import SparePartList from './ui/shared/spareparts/SparePartList.vue';
import Spinner from './ui/shared/Spinner.vue';
import Tab from './ui/shared/Tab.vue';
import Tabs from './ui/shared/Tabs.vue';
import TextField from './ui/shared/TextField.vue';
import PageLink from './ui/shared/PageLink.vue';
import LpButton from './ui/shared/LpButton.vue';
import ReadMoreWrapper from './ui/shared/ReadMoreWrapper.vue';
import ErrorMessage from './ui/shared/ErrorMessage.vue';
import RouterLinkConditional from '@/ui/shared/RouterLinkConditional.vue';
import DatePickerCtrl from './ui/shared/DatePickerCtrl.vue';
import DateRangePicker from '@/ui/shared/DateRangePicker.vue';
import ScriptEmbed from '@/ui/shared/ScriptEmbed.vue';
import BundleBannerBlock from '@/ui/shared/blocks/BundleBannerBlock.vue';
import Flowbox from '@/ui/shared/Flowbox.vue';
import FlowboxWithDynamicTags from '@/ui/shared/FlowboxWithDynamicTags.vue';
import NotificationSuccess from '@/ui/shared/NotificationSuccess.vue';
import InspirationalContent from './ui/shared/InspirationalContent.vue';
import InspirationalContentCell from './ui/shared/InspirationalContentCell.vue';
import ImageWithProductHotspots from './ui/shared/image/ImageWithProductHotspots.vue';
import FilterToggle from './ui/shared/FilterToggle.vue';
import NavigationLinkItem from './ui/shared/NavigationLinkItem.vue';
import CordCuttingModal from '@/ui/shared/CordCuttingModal.vue';

// views
import BasketB2C from './ui/views/checkout/BasketB2C.vue';
import BasketB2B from './ui/views/checkout/BasketB2B.vue';
import BasketPOS from './ui/views/checkout/BasketPOS.vue';
import CategoryPageB2C from './ui/views/category/CategoryPageB2C.vue';
import CategoryPageB2B from './ui/views/category/CategoryPageB2B.vue';
import CheckoutB2C from './ui/views/checkout/CheckoutB2C.vue';
import CheckoutPOS from './ui/views/checkout/CheckoutPOS.vue';
import PaymentB2C from '@/ui/views/checkout/PaymentB2C.vue';
import PaymentB2B from '@/ui/views/checkout/PaymentB2B.vue';
import CheckoutProgress from './ui/views/checkout/CheckoutProgress.vue';
import QuoteCheckoutProgress from '@/ui/views/checkout/QuoteCheckoutProgress.vue';
import CheckoutClearData from './ui/views/checkout/CheckoutClearData.vue';
import BasketSummaryTotal from '@/ui/views/checkout/BasketSummaryTotal.vue';
import PaymentMethodsB2B from '@/ui/views/checkout/PaymentMethodsB2B.vue';
import DownloadCenter from './ui/views/download-center/DownloadCenter.vue';
import DownloadSelection from './ui/views/product-details/variant-download/DownloadSelection.vue';
import GetQuote from './ui/views/product-details/GetQuote.vue';
import GetQuoteB2C from './ui/views/product-details/GetQuoteB2C.vue';
import FaqWrapper from './ui/views/faq/FaqWrapper.vue';
import ForgotPasswordView from './ui/views/forgotpassword/ForgotPassword.vue';
import ChangeOneTimePasswordView from './ui/views/changeonetimepassword/ChangeOneTimePassword.vue';
import ForgotPasswordConfirmationView from './ui/views/forgotpassword/ForgotPasswordConfirmation.vue';
import CreatePasswordView from './ui/views/createpassword/CreatePassword.vue';
import CreateUsernamePasswordView from './ui/views/createusernamepassword/CreateUsernamePassword.vue';
import UpdatePasswordView from './ui/views/updatepassword/UpdatePassword.vue';
import ResetPasswordView from './ui/views/resetpassword/ResetPassword.vue';
import OnPageSearch from './ui/views/search/OnPageSearch.vue';
import ProductDataSpecs from './ui/views/product-details/ProductDataSpecs.vue';
import ProductFactsB2B from './ui/views/product-details/ProductFactsB2B.vue';
import ProductFactsB2C from './ui/views/product-details/ProductFactsB2C.vue';
import ProductFeatureText from './ui/views/product-details/ProductFeatureText.vue';
import ProductLightDistribution from './ui/views/product-details/ProductLightDistribution.vue';
import ProductTenderInfo from './ui/views/product-details/ProductTenderInfo.vue';
import ProductVariantPicker from './ui/views/product-details/ProductVariantPicker.vue';
import ProductVariantPickerProfessional from './ui/views/product-details/ProductVariantPickerProfessional.vue';
import ProductVariantOverlay from './ui/views/product-details/ProductVariantOverlay.vue';
import ProductVariantSorting from './ui/views/product-details/variant-configurator/ProductVariantSorting.vue';
import ProductCategoryRedirect from './ui/views/product-details/product-category-redirect/ProductCategoryRedirect.vue';
import ProjectList from './ui/views/projects/ProjectList.vue';
import SearchResult from './ui/views/search/SearchResult.vue';
import FindSparePartPage from './ui/views/spareparts/FindSparePartPage.vue';
import StoreLocator from './ui/views/store-locator/StoreLocator.vue';
import Styleguide from './ui/views/styleguide/Styleguide.vue';
import VariantConfigurator from './ui/views/product-details/variant-configurator/VariantConfigurator.vue';
import ProductSpecification from './ui/views/product-configurator/ProductSpecification.vue';
import ProductConfigurator from './ui/views/product-details/ProductConfigurator.vue';
import VariantDragUldFiles from './ui/views/product-details/VariantDragUldFiles.vue';
import ReturnProduct from './ui/views/return-forms/ReturnProduct.vue';
import WarrantyForm from './ui/views/warranty-form/WarrantyForm.vue';
import ProfileInformation from '@/ui/views/myprofile/ProfileInformation.vue';
import DeliveryAddresses from '@/ui/views/myprofile/DeliveryAddresses.vue';
import InvoiceView from '@/ui/views/invoices/InvoiceView.vue';
import QuickOrder from '@/ui/views/quickorder/QuickOrder.vue';
import QuickOrderFileUpload from '@/ui/views/quickorder/QuickOrderFileUpload.vue';
import DashboardView from '@/ui/views/dashboard/DashboardView.vue';
import MostOrderedProducts from '@/ui/views/mostorderedproducts/MostOrderedProducts.vue';
import HrOnRecruit from '@/ui/views/hr-on-reccruit/HrOnRecruit.vue';
import ReflectionsPage from '@/ui/views/reflections-page/ReflectionsPage.vue';
import StockTransferOrder from '@/ui/views/point-of-sale/StockTransferOrder.vue';
import MfaEnterCodeView from '@/ui/views/mfa/MfaEnterCode.vue';

// Login
import LoginView from './ui/views/login/Login.vue';

import OldLoginView from './ui/views/old-login/OldLogin.vue';
import MyAccountLoginView from './ui/views/my-account-login/MyAccountLogin.vue';
import CreateAccountForm from './ui/views/my-account-login/CreateAccountForm.vue';
import BasketLoginPage from './ui/views/checkout/BasketLoginPage.vue';
import ConfirmAccountEmailView from './ui/views/confirmaccount/ConfirmAccountEmail.vue';

// MyAccount
import ProfileDetails from './ui/views/profile-details/ProfileDetails.vue';
import ProductRegistrationDashboardPage from '@/ui/views/product-registration/DashboardPage.vue';
import ProductRegistrationRegisteredProductPage from '@/ui/views/product-registration/RegisteredProductPage.vue';
import RegisterProductBySerialNumberPage from '@/ui/views/product-registration/RegisterProductBySerialNumberPage.vue';
import ProductRegistrationCatalogSearchPage from '@/ui/views/product-registration/CatalogSearchPage.vue';
import RegisterProductPage from '@/ui/views/product-registration/RegisterProductPage.vue';

// icons
import IconAccount from './ui/icons/IconAccount.vue';
import IconBase from './ui/icons/IconBase.vue';
import IconBasket from './ui/icons/IconBasket.vue';
import IconCheck from './ui/icons/IconCheck.vue';
import IconCheckSlender from './ui/icons/IconCheckSlender.vue';
import IconChevronDown from './ui/icons/IconChevronDown.vue';
import IconChevronLeft from './ui/icons/IconChevronLeft.vue';
import IconChevronRight from './ui/icons/IconChevronRight.vue';
import IconChevronUp from './ui/icons/IconChevronUp.vue';
import IconClose from './ui/icons/IconClose.vue';
import IconGlobe from './ui/icons/IconGlobe.vue';
import IconLeftArrow from './ui/icons/IconLeftArrow.vue';
import IconPdf from './ui/icons/IconPdf.vue';
import IconPencil from './ui/icons/IconPencil.vue';
import IconPhonemenu from './ui/icons/IconPhonemenu.vue';
import IconPin from './ui/icons/IconPin.vue';
import IconQuoteBasket from './ui/icons/IconQuoteBasket.vue';
import IconRightArrow from './ui/icons/IconRightArrow.vue';
import IconRuler from './ui/icons/IconRuler.vue';
import IconSearch from './ui/icons/IconSearch.vue';
import IconSorting from './ui/icons/IconSorting.vue';
import IconTrace from './ui/icons/IconTrace.vue';

// mixins
import { BreakpointsMixin } from './core/responsive/breakpoints/breakpoint.mixin';
import { TranslateMixin } from './core/translate.mixin';

// developer debug
import ThrowXhrError from './ui/testing/ThrowXhrError.vue';
import ThrowJsNullError from './ui/testing/ThrowJsNullError.vue';
import OrderView from '@/ui/views/orders/OrderView.vue';
import QuoteView from '@/ui/views/quotes/QuoteView.vue';
import ImageCompare from '@/ui/shared/ImageCompare.vue';
import QuoteBasketB2B from '@/ui/views/checkout/QuoteBasketB2B.vue';
import DownloadTenderText from '@/ui/views/product-details/variant-download/DownloadTenderText.vue';

import ProductCarousel from '@/ui/views/product-details/ProductCarousel.vue';

Vue.component('App', App);
Vue.component('AddToBasket', AddToBasket);
Vue.component('AddToBasketQuantity', AddToBasketQuantity);
Vue.component('AddToBasketWithQuantity', AddToBasketWithQuantity);
Vue.component('AddToQuoteBasket', AddToQuoteBasket);
Vue.component('AddToBasketMultiple', AddToBasketMultiple);
Vue.component('AccordionItem', AccordionItem);
Vue.component('BasketB2C', BasketB2C);
Vue.component('BasketB2B', BasketB2B);
Vue.component('PaymentB2B', PaymentB2B);
Vue.component('BasketPOS', BasketPOS);
Vue.component('QuoteBasketB2B', QuoteBasketB2B);
Vue.component('BlockAddToBasketCTA', BlockAddToBasketCTA);
Vue.component('BlockFeaturedContent', BlockFeaturedContent);
Vue.component('BlockSingleFeaturedContent', BlockSingleFeaturedContent);
Vue.component('BlockFeaturedLandscapeContent', BlockFeaturedLandscapeContent);
Vue.component('BlockLandscape', BlockLandscape);
Vue.component('BlockFullWidthImageWithText', BlockFullWidthImageWithText);
Vue.component('BlockLedCalculator', BlockLedCalculator);
Vue.component('BlockLedConfigurator', BlockLedConfigurator);
Vue.component('BlockImageWithInfoPoints', BlockImageWithInfoPoints);
Vue.component('BlockPortrait', BlockPortrait);
Vue.component('BlockTeaserTextWithOverlay', BlockTeaserTextWithOverlay);
Vue.component('BlockTextAndImage', BlockTextAndImage);
Vue.component('BlockTextAndImageSideBySide', BlockTextAndImageSideBySide);
Vue.component('BlockTopModule', BlockTopModule);
Vue.component('BlockVideo', BlockVideo);
Vue.component('BlockGdprForm', BlockGdprForm);
Vue.component('BlockTraderSignupForm', BlockTraderSignupForm);
Vue.component('BlockYourStoriesForm', BlockYourStoriesForm);
Vue.component('BlockCookiePolicy', BlockCookiePolicy);
Vue.component('BlockDismantlingInstructions', BlockDismantlingInstructions);
Vue.component('Breakpoint', Breakpoint);
Vue.component('CategoryPageB2C', CategoryPageB2C);
Vue.component('CategoryPageB2B', CategoryPageB2B);
Vue.component('CheckoutB2C', CheckoutB2C);
Vue.component('CheckoutPOS', CheckoutPOS);
Vue.component('PaymentB2C', PaymentB2C);
Vue.component('CheckoutProgress', CheckoutProgress);
Vue.component('QuoteCheckoutProgress', QuoteCheckoutProgress);
Vue.component('CheckoutClearData', CheckoutClearData);
Vue.component('DownloadCenter', DownloadCenter);
Vue.component('DownloadSelection', DownloadSelection);
Vue.component('Dropdown', Dropdown);
Vue.component('Flickity', Flickity);
Vue.component('FilterSlider', FilterSlider);
Vue.component('FaqWrapper', FaqWrapper);
Vue.component('GridFallback', GridFallback);
Vue.component('Headline', Headline);
Vue.component('Logo', Logo);
Vue.component('CreatePasswordView', CreatePasswordView);
Vue.component('CreateUsernamePasswordView', CreateUsernamePasswordView);
Vue.component('UpdatePasswordView', UpdatePasswordView);
Vue.component('ResetPasswordView', ResetPasswordView);
Vue.component('ForgotPasswordView', ForgotPasswordView);
Vue.component('ChangeOneTimePasswordView', ChangeOneTimePasswordView);
Vue.component('ForgotPasswordConfirmationView', ForgotPasswordConfirmationView);
Vue.component('UpdatePasswordView', UpdatePasswordView);
Vue.component('LastViewedProducts', LastViewedProducts);
Vue.component('InputField', InputField);
Vue.component('PasswordField', PasswordField);
Vue.component('Checkbox', Checkbox);
Vue.component('ImageCarousel', ImageCarousel);
Vue.component('FullscreenImageCarousel', FullscreenImageCarousel);
Vue.component('Modal', Modal);
Vue.component('ReadMoreWrapper', ReadMoreWrapper);
Vue.component('InspirationalContent', InspirationalContent);
Vue.component('InspirationalContentCell', InspirationalContentCell);
Vue.component('SleekModal', SleekModal);
Vue.component('Flyover', Flyover);
Vue.component('VariantDragUldFiles', VariantDragUldFiles);
Vue.component('ProductFactsB2B', ProductFactsB2B);
Vue.component('ProductFactsB2C', ProductFactsB2C);
Vue.component('ProductDataSpecs', ProductDataSpecs);
Vue.component('ProductFeatureText', ProductFeatureText);
Vue.component('ProductTenderInfo', ProductTenderInfo);
Vue.component('DownloadTenderText', DownloadTenderText);
Vue.component('ProductTile', ProductTile);
Vue.component('ContentTile', ContentTile);
Vue.component('ProductVariantPicker', ProductVariantPicker);
Vue.component('ProductVariantPickerProfessional', ProductVariantPickerProfessional);
Vue.component('ProductVariantOverlay', ProductVariantOverlay);
Vue.component('ProductVariantSorting', ProductVariantSorting);
Vue.component('ProductCategoryRedirect', ProductCategoryRedirect);
Vue.component('ProjectList', ProjectList);
Vue.component('VariantConfigurator', VariantConfigurator);
Vue.component('ProductSpecification', ProductSpecification);
Vue.component('ProductConfigurator', ProductConfigurator);
Vue.component('GetQuote', GetQuote);
Vue.component('GetQuoteB2C', GetQuoteB2C);
Vue.component('ProductLightDistribution', ProductLightDistribution);
Vue.component('SparePartList', SparePartList);
Vue.component('ImagePresenter', ImagePresenter);
Vue.component('SimpleImagePresenter', SimpleImagePresenter);
Vue.component('NewsletterForm', NewsletterForm);
Vue.component('NewNewsletterForm', NewNewsletterForm);
Vue.component('NoImage', NoImage);
Vue.component('RadioButton', RadioButton);
Vue.component('ReturnProduct', ReturnProduct);
Vue.component('WarrantyForm', WarrantyForm);
Vue.component('Spinner', Spinner);
Vue.component('StoreSync', StoreSync);
Vue.component('OnPageSearch', OnPageSearch);
Vue.component('SearchResult', SearchResult);
Vue.component('FindSparePartPage', FindSparePartPage);
Vue.component('StoreLocator', StoreLocator);
Vue.component('Styleguide', Styleguide);
Vue.component('TextField', TextField);
Vue.component('Tab', Tab);
Vue.component('Tabs', Tabs);
Vue.component('PageLink', PageLink);
Vue.component('LpButton', LpButton);
Vue.component('ErrorMessage', ErrorMessage);
Vue.component('RouterLinkConditional', RouterLinkConditional);
Vue.component('DatePickerCtrl', DatePickerCtrl);
Vue.component('DateRangePicker', DateRangePicker);
Vue.component('ImageCompare', ImageCompare);
Vue.component('HrOnRecruit', HrOnRecruit);
Vue.component('ScriptEmbed', ScriptEmbed);
Vue.component('BundleBannerBlock', BundleBannerBlock);
Vue.component('Flowbox', Flowbox);
Vue.component('FlowboxWithDynamicTags', FlowboxWithDynamicTags);
Vue.component('NotificationSuccess', NotificationSuccess);
Vue.component('ReflectionsPage', ReflectionsPage);
Vue.component('ImageWithProductHotspots', ImageWithProductHotspots);
Vue.component('FilterToggle', FilterToggle);
Vue.component('NavigationLinkItem', NavigationLinkItem);
Vue.component('CordCuttingModal', CordCuttingModal);
Vue.component('BasketSummaryTotal', BasketSummaryTotal);
Vue.component('StockTransferOrder', StockTransferOrder);
Vue.component('PaymentMethodsB2B', PaymentMethodsB2B);
Vue.component('CheckoutButtonB2B', CheckoutButtonB2B);

// Login
Vue.component('LoginView', LoginView);
Vue.component('OldLoginView', OldLoginView);
Vue.component('MyAccountLoginView', MyAccountLoginView);
Vue.component('CreateAccountForm', CreateAccountForm);
Vue.component('BasketLoginPage', BasketLoginPage);
Vue.component('ConfirmAccountEmailView', ConfirmAccountEmailView);

// My Account
Vue.component('ProfileDetails', ProfileDetails);
Vue.component('ProductRegistrationDashboardPage', ProductRegistrationDashboardPage);
Vue.component('ProductRegistrationRegisteredProductPage', ProductRegistrationRegisteredProductPage);
Vue.component('RegisterProductBySerialNumberPage', RegisterProductBySerialNumberPage);
Vue.component('ProductRegistrationCatalogSearchPage', ProductRegistrationCatalogSearchPage);
Vue.component('RegisterProductPage', RegisterProductPage);

// Mfa
Vue.component('MfaEnterCodeView', MfaEnterCodeView);

// Partner Portal
Vue.component('ProfileInformation', ProfileInformation);
Vue.component('DeliveryAddresses', DeliveryAddresses);
Vue.component('InvoiceView', InvoiceView);
Vue.component('OrderView', OrderView);
Vue.component('QuoteView', QuoteView);
Vue.component('QuickOrder', QuickOrder);
Vue.component('QuickOrderFileUpload', QuickOrderFileUpload);
Vue.component('DashboardView', DashboardView);
Vue.component('MostOrderedProducts', MostOrderedProducts);

// register developer debug components
Vue.component('ThrowXhrError', ThrowXhrError);
Vue.component('ThrowJsNullError', ThrowJsNullError);

// register icons
Vue.component('IconAccount', IconAccount);
Vue.component('IconBase', IconBase);
Vue.component('IconBasket', IconBasket);
Vue.component('IconCheck', IconCheck);
Vue.component('IconCheckSlender', IconCheckSlender);
Vue.component('IconChevronDown', IconChevronDown);
Vue.component('IconChevronLeft', IconChevronLeft);
Vue.component('IconChevronRight', IconChevronRight);
Vue.component('IconChevronUp', IconChevronUp);
Vue.component('IconClose', IconClose);
Vue.component('IconGlobe', IconGlobe);
Vue.component('IconLeftArrow', IconLeftArrow);
Vue.component('IconPdf', IconPdf);
Vue.component('IconPencil', IconPencil);
Vue.component('IconPhonemenu', IconPhonemenu);
Vue.component('IconPin', IconPin);
Vue.component('IconQuoteBasket', IconQuoteBasket);
Vue.component('IconRightArrow', IconRightArrow);
Vue.component('IconRuler', IconRuler);
Vue.component('IconSearch', IconSearch);
Vue.component('IconSorting', IconSorting);
Vue.component('IconTrace', IconTrace);

// register mixins
Vue.mixin(BreakpointsMixin);
Vue.mixin(TranslateMixin);

addBreakpoint('small', 0, 759);
addBreakpoint('medium', 760, 1024);
addBreakpoint('large', 1025, 999999);
Vue.component('ProductCarousel', ProductCarousel);
